/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import "./styles/MyResources.css";
import "./styles/Button.css";
import { useAlert } from "react-alert";
import { validateFile, validateThumbnail } from "../../utils/helper.js";
// import TablePagination from "@material-ui/core/TablePagination";
import { NavLink } from "react-router-dom";

import axios from "axios";
import { GlobalContext } from "../../context/GlobalState";
import { useForm } from "react-hook-form";
// import { useAlert } from "react-alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import image from "../../utils/helpers.js";

import "bootstrap";
import { useMediaQuery } from "react-responsive";
import ResourceHeader from "./ResourceHeader.jsx";

const { isDisabled } = require("../../utils/util.js");

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

const MyResources = () => {
  const alert = useAlert();

  document.title = "2xcell.in";

  const [assignedSections, setAssignedSections] = useState([]);
  const [assignedChapters, setAssignedChapters] = useState([]);
  const [assignedTopics, setAssignedTopics] = useState([]);
  const [fetchSubjectLoader, setFetchSubjectLoader] = useState(false);
  const [addContentMessage, setAddContentMessage] = useState("");
  const [addContentLoader, setAddContentLoader] = useState(false);

  const {
    lang,
    setLanguage,
    eligible,
    userDetails,
    setUserDetails,
    assignedGrades,
    assignedSubjects,
    setAssignedSubjects,
    selectedClass,
    setSelectedClass,
    setSectionId,
  } = useContext(GlobalContext);

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const fetchSections = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setAssignedSections([response.data.data.staff.sections]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSections();
  }, []);

  const fetchSubjects = () => {
    setFetchSubjectLoader(true);
    let tempArr = [];
    assignedGrades.grades.forEach((assignedClass) => {
      if (selectedClass === assignedClass._id) {
        window.localStorage.setItem("selectedClass", assignedClass._id);
        assignedClass.subjects.forEach((data) => {
          localStorage.setItem("sectionIdTask", data.sectionId);
          tempArr.push({
            subject: data.subject,
            subjectId: data.subjectId,
            subjectData: data.subjectData,
          });
        });
      }
    });

    setAssignedSubjects([tempArr]);
    setFetchSubjectLoader(false);
  };

  const handleSelectClass = (e, data) => {
    const sectionId = data.subjects.length && data.subjects[0].sectionId;
    // sectionId and class stored in context for further request
    setSectionId(sectionId);
    // sectionId and class are persisted so no data loss while refresh
    localStorage.setItem("currentsectionId", sectionId);
    localStorage.setItem("gradeSection", e.target.value);
    setSelectedClass(e.target.value);
    // improvement needed - sending section through route params is recommended than localStorage
  };

  const getChapters = async (e) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${e.target.value}`,
        {
          headers: {
            token: authToken,
          },
        }
      );

      if (response.data.status === "success") {
        setAssignedChapters([response.data.data.subject.chapters]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTopics = async (e) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${e.target.value}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        console.log(response);
        setAssignedTopics([response.data.data.chapter.topics]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitContentForm = async (data) => {
    setAddContentMessage("");
    const {
      contentType,
      makeTask,
      name,
      sectionId,
      subjectId,
      taskType,
      topicId,
      lang,
      files,
      thumbnail,
    } = data;

    const file = files[0];
    const thumbnailPhoto = thumbnail[0];
    if (thumbnailPhoto && !validateThumbnail(thumbnailPhoto)) {
      alert.show("Please select valid file", { type: "error" });
      return;
    }
    if (file && !validateFile(file)) {
      alert.show("Please select valid file", { type: "error" });
      return;
    }
    setAddContentLoader(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    formData.append("topicId", topicId);
    formData.append("sectionId", sectionId);
    formData.append("subjectId", subjectId);
    formData.append("makeTask", makeTask);
    formData.append("contentType", contentType);
    formData.append("lang", lang);
    formData.append("taskType", taskType);
    formData.append("thumbnail", thumbnailPhoto);

    const authToken = localStorage.getItem("authToken");
    try {
      let url = "";

      if (contentType === "worksheet") {
        url = `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/worksheets`;
      } else {
        url = `${process.env.REACT_APP_FILE_SERVER}/api/v1/staffs/${userDetails.userId}/modules`;
      }

      const response = await axios.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          token: authToken,
        },
      });

      if (response.data.status === "success") {
        setAddContentMessage("Module Added.");
        setAddContentLoader(false);
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
        setAddContentMessage(error.response.data.message);
        setAddContentLoader(false);
      } else {
        setAddContentMessage("Try Again.");
        setAddContentLoader(false);
      }
    }

    setAddContentLoader(false);
  };

  //convert html, used for url link of each card in different language
  function decodeHTMLEntities(text) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  //Function to fix the thumbnail url
  const fixThumbnailUrl = (url) => {
    if (!url) {
      return "";
    }

    if (url.startsWith("https://")) {
      return url;
    } else {
      return "https://xcell-cms-files.s3.amazonaws.com/" + url;
    }
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  useEffect(() => {
    window.localStorage.setItem("language", lang);
  }, [lang]);

  useEffect(() => {
    window.$("#classesModal").modal("show");
  }, []);

  useEffect(() => {
    document.getElementById("hamburger_btn").addEventListener("click", () => {
      window.$("#classesModal").modal("hide");
      document.body.style.overflow = "scroll";
    });
  }, []);

  return (
    <>
      <section
        className={
          isSmallScreen
            ? "myResources my-2 p-lg-0 p-4"
            : "myResources my-5 p-lg-0 p-4"
        }
      >
        <div className="container">
          <ResourceHeader
            isSmallScreen={isSmallScreen}
            selectedGrade={selectedClass}
          />

          {eligible && (
            <div className="language_button_container">
              <span>
                <button
                  type="button"
                  className={
                    lang === "english"
                      ? "language_button selected_language"
                      : "language_button"
                  }
                  id="english_button"
                  onClick={() => {
                    setLanguage("english");
                  }}
                >
                  ENGLISH
                </button>
                <button
                  type="button"
                  className={
                    lang === "hindi"
                      ? "language_button selected_language"
                      : "language_button"
                  }
                  id="hindi_button"
                  onClick={() => {
                    setLanguage("hindi");
                  }}
                >
                  HINDI
                </button>
              </span>
            </div>
          )}

          {/* <div>
            <TablePagination
              component="div"
              count={100}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div> */}

          {fetchSubjectLoader ? (
            <div className="text-center">
              <CircularProgress
                style={{ color: "#FF075B", fontWeight: "600" }}
              />
            </div>
          ) : (
            <div className="row my-3 gy-4">
              {assignedSubjects[0]
                ? assignedSubjects[0]
                    .filter((asub) => asub?.subjectData?.lang === lang)
                    .map((assignedSubject, index) => (
                      <div
                        className="col-sm-2 cardBox"
                        key={index}
                        onClick={() =>
                          setUserDetails({
                            ...userDetails,
                            pathName: {
                              subjectName: `${decodeHTMLEntities(
                                assignedSubject.subject
                              )}`,
                              chapterName: "",
                              topicName: "",
                            },
                          })
                        }
                        style={{
                          display:
                            isDisabled(
                              assignedSubject?.subjectData?.chapters
                            ) || assignedSubject?.subjectData?.disabled
                              ? "none"
                              : "block",
                        }}
                      >
                        <NavLink
                          to={{
                            pathname: `/myresources/${decodeHTMLEntities(
                              fixName(assignedSubject.subject)
                            )}/${assignedSubject.subjectId}`,
                          }}
                          style={{
                            marginBottom: "3rem",
                            textDecoration: "none",
                          }}
                        >
                          {/* <div
                        className='text-center'
                        style={MyResourcesStyle(
                          fixThumbnailUrl(assignedSubject.subjectData.thumbnail)
                        )}
                      > */}
                          <div className="card card-radius card-height boxShadowCard">
                            <div className="card-img">
                              <img
                                src={image(
                                  fixThumbnailUrl(
                                    assignedSubject?.subjectData?.thumbnail
                                  )
                                )}
                                className="card-thumbnail"
                                alt={assignedSubject?.subject || "chapter"}
                              />
                            </div>
                            <div className="text-center">
                              <h5
                                style={{ fontWeight: "bold", color: "#808080" }}
                              >
                                {decodeHTMLEntities(assignedSubject.subject)}
                              </h5>
                              <p className="text-dark">
                                {"Chapters "}
                                {assignedSubject.subjectData?.totalChapters ||
                                  0}
                              </p>
                            </div>
                          </div>
                          {/* </div> */}
                        </NavLink>
                      </div>
                    ))
                : // (
                  //   <h1 className='text-center'>No Data to Show</h1>
                  // )
                  ""}
            </div>
          )}
        </div>
      </section>

      {/* add content Modal */}
      <div
        className="modal fade contentModal mt-5"
        id="contentModal"
        tabIndex="-1"
        aria-labelledby="contentModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="contentModalLabel">
                ADD CONTENT
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(submitContentForm)}>
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="name"
                      placeholder="Enter Name"
                      {...register("name")}
                    />
                  </div>
                  <div className="col-sm">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="makeTaskSelect"
                      {...register("makeTask")}
                    />{" "}
                    <label htmlFor="makeTaskSelect" className="form-label">
                      Make Task
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="makeTaskSelect"
                      {...register("taskType")}
                    >
                      <option defaultValue="">Select Task Type</option>
                      <option value="revision task">Revision Task</option>
                      <option value="flipped class">Flipped Class</option>
                      <option value="homework">Home Work</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="subjectSelect" className="form-label">
                      Subject
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="subjectSelect"
                      {...register("subjectId")}
                      onChange={getChapters}
                    >
                      <option defaultValue="">Select Subject</option>
                      {assignedSubjects[0] &&
                        assignedSubjects[0].map((assignedSubject, index) => (
                          <option key={index} value={assignedSubject.subjectId}>
                            {assignedSubject.subject}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="sectionSelect" className="form-label">
                      Section
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="sectionSelect"
                      {...register("sectionId")}
                    >
                      <option defaultValue="">Select Section</option>
                      {assignedSections[0] &&
                        assignedSections[0].map((assignedSection, index) => (
                          <option key={index} value={assignedSection.sectionId}>
                            {`${assignedSection.grade}-${assignedSection.section}`}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="chapterSelect" className="form-label">
                    Chapter
                  </label>
                  <select
                    className="form-select form-select-sm"
                    id="chapterSelect"
                    {...register("chapterId")}
                    onChange={getTopics}
                  >
                    <option defaultValue="">Select Chapter</option>
                    {assignedChapters[0] &&
                      assignedChapters[0].map((assignedChapter, index) => (
                        <option key={index} value={assignedChapter._id}>
                          {assignedChapter.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="topicSelect" className="form-label">
                      Topic
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="topicSelect"
                      {...register("topicId")}
                    >
                      <option defaultValue="">Select Topic</option>
                      {assignedTopics[0] &&
                        assignedTopics[0].map((assignedTopic, index) => (
                          <option key={index} value={assignedTopic._id}>
                            {assignedTopic.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="contentTypeSelect" className="form-label">
                      Content Type
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="contentTypeSelect"
                      {...register("contentType")}
                    >
                      <option defaultValue="">Select Content Type</option>
                      <option value="module">Module</option>
                      {/*<option value="worksheet">Assesment</option>*/}
                      {/* <option value="test">Test</option> */}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="topicSelect" className="form-label">
                      Language
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="languageSelect"
                      {...register("lang")}
                    >
                      <option defaultValue="">Select Language</option>
                      <option value="english">English</option>
                      <option value="hindi">Hindi</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="thumbnail" className="form-label">
                      Thumbnail
                    </label>
                    <input
                      className="form-control form-control-sm"
                      type="file"
                      id="thumbnail"
                      {...register("thumbnail")}
                    />
                    <small>Upload .jpg, .jpeg, .png file</small>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="s" className="form-label">
                      File
                    </label>
                    <input
                      className="form-control form-control-sm"
                      type="file"
                      id="files"
                      {...register("files")}
                    />
                    <small>
                      Upload .pdf, .ppt, .pptx, .m4v, .mp4, .docx file
                    </small>
                  </div>
                </div>
              </div>

              {addContentMessage && (
                <div className="text-center">
                  <p style={{ color: "#FF075B", fontWeight: "600" }}>
                    {addContentMessage}
                  </p>
                </div>
              )}

              <div className="modal_button_container">
                <div className="row">
                  <div className="col d-grid">
                    <button
                      type="button"
                      className="btn contentModalBtnCancel"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <div className="col d-grid">
                    {addContentLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          style={{ color: "#FF075B", fontWeight: "600" }}
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn  contentModalBtnApply"
                        // onClick={openSubjectModal}
                        // data-bs-dismiss="modal"
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Classes Modal */}

      <div
        className="modal fade classModel"
        id="classesModal"
        tabIndex="-1"
        aria-labelledby="classesModalLabel"
        // data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="classesModalLabel">
                GRADES
              </h4>
              {/* <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button> */}
            </div>
            {assignedGrades.loading ? (
              <div className="text-center">
                <CircularProgress
                  style={{ color: "#FF075B", fontWeight: "600" }}
                />
              </div>
            ) : (
              <div className="modal-body">
                <div className="row">
                  {assignedGrades.grades.map((assignedClass, index) => (
                    <div
                      className={isSmallScreen ? "col-4" : "col-sm-3"}
                      key={index}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="classRadio"
                          id={assignedClass._id}
                          onChange={(e) => handleSelectClass(e, assignedClass)}
                          required
                          value={assignedClass._id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={assignedClass._id}
                        >
                          {assignedClass._id}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="modal_button_container">
              <button
                type="button"
                id="ModalToggler"
                className="btn  classModelBtnApply"
                onClick={fetchSubjects}
                data-bs-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyResources;
