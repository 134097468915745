import Moment from "react-moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

export default function ResourceHeader({ selectedGrade, isSmallScreen }) {
  return (
    <div className="box mb-5" style={{ display: isSmallScreen ? "none" : "" }}>
      <div className="row">
        <div className="boxRow">
          <div
            className="col-sm-8 class-subject-text"
            data-bs-toggle="modal"
            data-bs-target="#classesModal"
          >
            <h1 className="fw-bold">{selectedGrade || "GRADES"}</h1>
            {/* <h4>All Subjects</h4> */}
          </div>
          {/* <div className="col-sm"> */}
          <div className="col-sm mx-5">
            <div className="DateBox">
              <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                <CalendarTodayIcon />{" "}
                {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
              </h5>
            </div>
            <p
              className="mt-4 fw-bolder"
              data-bs-toggle="modal"
              data-bs-target="#contentModal"
              style={{ cursor: "pointer" }}
            >
              <AddBoxOutlinedIcon /> Add Content
            </p>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
