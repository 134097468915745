/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import "./styles/Dashboard.css";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import MeetIcon from "../../images/meet_icon.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Updates from "./Updates";
import RevisionTask from "./RevisionTask";
import FlippedClass from "./FlippedClass";
import Homework from "./Homework";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.js";
import Moment from "react-moment";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import { ReactComponent as AnalyticsIcon } from "../../images/analyticGraph.svg";
import { ReactComponent as TaskIcon } from "../../images/assign.svg";
import { ReactComponent as DashboardIcon } from "../../images/Dashboard.svg";
import AssessmentIcon from "../../images/asses.svg";
import LessonImg from "../../images/Lesson.svg";
import WhiteBoardImg from "../../images/whiteBoard.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Assesment from "../MyAssessment/Assesment";
import TaskDashboard from "./TaskDashboard";
import ShutterOpen from "../../images/ShutterDown.svg";
import ShutterClose from "../../images/ShutterUp.svg";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import assignImg from "../../images/assign.svg";
import HomeIcon from "../../images/HomeIcon.svg";
import DropDownShutter from "../DropDown/DropDown";
import GradeSorter from "../../utils/gradeSorter";
import Analytics from "../AssessmentAnalytics/Analytics";
// import GradeSorter from '../../utils/gradeSorter';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#edfcfa",
    boxShadow: "none",
    borderRadius: "0px 0px 10px 10px",
    display: "flex",
    justifyContent: "center",
  },
  dashboardTabStyle: {
    color: "#10A0B6",
    minWidth: "100px",
    fontWeight: "normal !important",
  },
  bottomSectionStyle: {
    color: "white",
    fontSize: "0.8em",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  document.title = "2xcell.in";
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [selectedClass, setSelectedClass] = useState("");
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const tabList = ["Dashboard", "Analytics", "Assign Task", "Assessment"];
  const { userDetails, setUserDetails, assignedGrades } =
    useContext(GlobalContext);
  const [assessmentMakeTaskLoader, setAssessmentMakeTaskLoader] =
    useState(false);
  const [section, setSection] = useState("Section");
  const [subjectName, setSubjectName] = useState("Subject");
  const [chapterName, setChapterName] = useState("Chapter");
  const [topicName, setTopicName] = useState("Topic");
  const [selectedDetails, setSelectedDetails] = useState({
    class: "Class",
    gradesection: "Section",
    subject: "Subject",
  });
  const newClass = [];
  const newgrade = [];
  const grade = [];
  const newsection = [];
  const sectionsel = [];
  const setSubject = [];
  const subject = [];

  const openSubjectModal = () => {
    let tempArr = [];
    assignedGrades.grades.forEach((assignedClass) => {
      if (selectedClass === assignedClass._id) {
        assignedClass.subjects.forEach((data) => {
          tempArr.push({
            subject: data.subject,
            subjectId: data.subjectId,
          });
        });
      }
    });

    setAssignedSubjects([tempArr]);
  };
  assignedGrades.grades.forEach((list) => newClass.push(list));
  const parseGrades = (grades) => {
    const newGrades = [];
    grades.forEach((gradeSection) => {
      var grade = null,
        section = null;
      const splittedGrade = gradeSection._id.split("-");
      if (splittedGrade.length === 3) {
        grade = `${splittedGrade[0]}-${splittedGrade[1]}`;
        section = splittedGrade[splittedGrade.length - 1];
      } else {
        grade = splittedGrade[0];
        section = splittedGrade[1];
      }
      const gradeIndex = newGrades.findIndex(
        (newGrade) => newGrade.grade === grade
      );
      if (gradeIndex > -1) {
        newGrades[gradeIndex].sections.push({
          section,
          subjects: gradeSection.subjects,
        });
      } else {
        newGrades.push({
          grade,
          sections: [
            {
              section,
              subjects: gradeSection.subjects,
            },
          ],
        });
      }
    });
    newgrade.push(newGrades);
  };
  parseGrades(newClass);
  console.log("newClass", newClass);
  newgrade?.forEach((x, i) => {
    x.forEach((l, i) => {
      console.log("class", l.grade);
      grade.push(l.grade);
      if (l.grade === selectedDetails.class) {
        newsection.push(l.sections);
      }
    });
    console.log("classfinal", grade);
  });
  newsection?.forEach((x, i) => {
    x?.forEach((y, i) => {
      sectionsel.push(y?.section);
      setSubject.push(y?.subjects);
    });
  });
  setSubject.forEach((x) => {
    x.forEach((y) => {
      subject.push(y);
    });
  });
  const handleSelectSubject = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);
  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };
  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = function () {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en", // set the default language of your page
          //includedLanguages: "en,hi,ar", // set the languages you want to allow translation to
          layout:
            window.google.translate.TranslateElement.InlineLayout.HORIZONTAL, // set the layout of the button
        },
        "google_translate_element"
      );
    };
  }, []);

  return (
    <>
      <div id="google_translate_element"></div>
      <section className={isSmallScreen ? "dashboard" : "dashboard my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              // data-bs-toggle="modal"
              style={{ display: !isSmallScreen ? "" : "none" }}
            >
              <div className="row">
                <div className="col-sm text-center text-sm-start">
                  <h1 style={{ fontWeight: "bolder" }}>{tabList[value]}</h1>
                </div>
                <div className="col-sm" />
                <div className="col-sm-3">
                  <h5
                    className="ms-auto"
                    style={{
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "column",
                      alignItem: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <CalendarTodayIcon />{" "}
                      {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                    </div>
                    <img
                      src={MeetIcon}
                      style={{
                        height: "30px",
                        width: "30px",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        window.location.href = "https://meet.google.com";
                      }}
                    />
                  </h5>
                </div>
              </div>
            </div>

            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                style={{
                  background: "#DCFFFB",
                  display: dropdown ? "flex" : "none",
                  width: "100%",
                  justifyContent: "space-evenly",
                  marginBottom: "20px",
                }}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons={"on"}
              >
                <Tab
                  className={classes.dashboardTabStyle}
                  icon={
                    <img
                      src={HomeIcon}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Home"
                  {...a11yProps(0)}
                  onClick={() => changeHeading("Dashboard")}
                />
                <Tab
                  style={{ color: "#10A0B6" }}
                  icon={
                    <img
                      src={analyticGraphImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Analytics"
                  {...a11yProps(1)}
                  onClick={() => changeHeading("Analytics")}
                />
                <Tab
                  className={classes.dashboardTabStyle}
                  icon={
                    <img
                      src={assignImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Assign Task"
                  {...a11yProps(2)}
                  onClick={() => changeHeading("Assign Task")}
                />
                <Tab
                  className={classes.dashboardTabStyle}
                  icon={
                    <img
                      src={assessmentImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Assessment"
                  {...a11yProps(3)}
                  onClick={() => changeHeading("Assessment")}
                />
              </Tabs>
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </Paper>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                className="fixScroll"
              >
                <Updates
                  selectedclassName={selectedClass}
                  grader={grade}
                  sectionsel={sectionsel}
                  subject={subject}
                  selectedDetails={selectedDetails}
                  setSelectedDetails={setSelectedDetails}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Analytics />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <TaskDashboard />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                {assessmentMakeTaskLoader && (
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                )}
                <Assesment
                  setSection={setSection}
                  setSelectedclassName={setSelectedClass}
                  setSubjectName={setSubjectName}
                  setChapterName={setChapterName}
                  setTopicName={setTopicName}
                />
              </TabPanel>
            </SwipeableViews>
            {/* <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction} className="fixScroll">
                <Updates selectedclassName={selectedClass} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <RevisionTask
                  selectedclassName={selectedClass}
                  selectedSubject={selectedSubject}
                />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <FlippedClass
                  selectedclassName={selectedClass}
                  selectedSubject={selectedSubject}
                />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <Homework
                  selectedclassName={selectedClass}
                  selectedSubject={selectedSubject}
                />
              </TabPanel>
            </SwipeableViews> */}
          </div>
        </div>
      </section>
      {/* Subject Modal */}
      <div
        className="modal fade subjectModal mt-5"
        id="subjectsModal"
        tabIndex="-1"
        aria-labelledby="subjectsModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="subjectsModalLabel">
                SUBJECTS
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                {assignedSubjects[0] &&
                  assignedSubjects[0].map((assignedSubject, index) => (
                    <div className="col-sm-4" key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sujectRadio"
                          id={assignedSubject.subjectId}
                          onChange={handleSelectSubject}
                          required
                          value={assignedSubject.subject}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={assignedSubject.subjectId}
                        >
                          {assignedSubject.subject}
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="mb-3 p-2">
              <div className="row">
                {/* <div className="col d-grid">
                  <button
                    type="button"
                    className="btn subjectModalBtnCancel"
                    onClick={()=>{clearCheckedValues('sujectRadio')}}
                  >
                    Clear
                  </button>
                </div> */}
                <div className="col d-grid">
                  <button
                    type="button"
                    className="btn  subjectModalBtnApply"
                    // onClick={getChapters}
                    data-bs-dismiss="modal"
                    style={{ width: "50%", margin: "auto" }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
