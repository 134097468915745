import { useState, useMemo, useRef, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import "./ModuleSearch.css";
import { GlobalContext } from "../../context/GlobalState";


import ModuleSvg from "../../images/module.svg";

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

function List(props) {
  const { course, subject, chapter, topic, module } = useMemo(() => {
    return {
      country:
        props.mod.topicId?.chapterId?.subjectId?.courseId?.boardId?.countryId ||
        null,
      board: props.mod.topicId?.chapterId?.subjectId?.courseId?.boardId || null,
      course: props.mod.topicId?.chapterId?.subjectId?.courseId || null,
      subject: props.mod.topicId?.chapterId?.subjectId || null,
      chapter: props.mod.topicId?.chapterId || null,
      topic: props.mod.topicId || null,
      module: {
        id: props.mod._id,
        name: props.mod.name,
      },
    };
  }, [props.mod]);
  function gotoLink() {
    props.setValue({ subject, chapter, topic, module });
    window.location.href = `/myresources/${fixName(subject.name)}/${
      subject._id
    }/${fixName(chapter.name)}/${chapter._id}/${fixName(topic.name)}/${
      topic._id
    }?module=${module.id}`;
  }
  return (
    <li key={props.index} className="search-data" onClick={gotoLink}>
      <img src={props.mod.thumbnail || ModuleSvg} alt={module.name} onError={(e) => {
         e.currentTarget.src = ModuleSvg;
      }}/>
      <div>
        <h5>{module?.name || ""}</h5>
        <p>
          <span>{course?.name || ""}</span>
          <span>{">"}</span>
          <span>{subject?.name || ""}</span>
          <span>{">"}</span>
          <span>{chapter?.name || ""}</span>
          <span>{">"}</span>
          <span>{topic?.name || ""}</span>
        </p>
      </div>
    </li>
  );
}

export function ModuleSearch() {
  const [loading, setLoading] = useState("idle");
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const paginationRef = useRef({ limit: 30, page: 0 });
  const { assignedGrades, subjectIds } = useContext(GlobalContext);

  function setValue(moduleData) {
    const data = subjectIds[moduleData.subject?._id];
    window.localStorage.setItem("gradeSection", data.gradeSection);
    window.localStorage.setItem("currentsectionId", data.sectionId);
    window.localStorage.setItem("selectedClass", data.gradeSection);
    window.localStorage.setItem("sectionIdTask", data.sectionId);
    window.localStorage.setItem("subjectId", data.subject?._id);
    window.localStorage.setItem("chapterId", data.chapter?._id);
    window.localStorage.setItem("topicId", moduleData?.topic._id);
    window.localStorage.setItem("moduleId", moduleData?.module.id);
  }

  function reset() {
    paginationRef.current = { limit: 30, page: 0 };
    setResults([]);
    setLoading("idle");
  }

  async function searchResult(prevResults) {
    if (assignedGrades.loading || !search || loading === "pending") return;
    setLoading("pending");
    const authToken = localStorage.getItem("authToken");
    const newPage = paginationRef.current.page + 1;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/api/v1/modules/search-subject-modules?name=${search}&page=${newPage}&limit=${paginationRef.current.limit}`,
        { subjects: Object.keys(subjectIds) },
        {
          headers: {
            token: authToken,
          },
        }
      );
      const modules = response.data.data.modules;
      if (modules.length) paginationRef.current.page = newPage;
      //reset to previous
      setResults([...prevResults, ...modules]);
    } catch (err) {
      alert("Something went wrong");
    }
    setLoading("loaded");
  }

  async function submit(event) {
    event.preventDefault();
    reset();
    await searchResult([]);
  }

  const toggleLMButton = useMemo(() => {
    if (!loading && search) {
      if (
        results.length <
        paginationRef.current.limit * paginationRef.current.page
      )
        return false;
      if (results.length) return true;
    }
    return false;
  }, [results, loading, search]);

  //Search Module
  return (
    <div className="module-search">
      <form
        onSubmit={submit}
        className={assignedGrades.loading ? "disabled" : ""}
      >
        <div>
          <input
            placeholder={"Search..."}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            type={"text"}
          />
          {loading === "loaded" ? (
            <CloseIcon
              className="searchIcon"
              onClick={() => {
                setSearch("");
                reset();
              }}
            />
          ) : (
            <SearchIcon className="searchIcon" />
          )}
        </div>
      </form>
      <div className="search-list">
        <ul>
          {results.map((mod, index) => {
            return <List setValue={setValue} key={index} mod={mod} />;
          })}
          {toggleLMButton && (
            <li onClick={() => searchResult(results)} className="load-more">
              Load more
            </li>
          )}
          {search && loading === "pending" ? (
            <li className="load-more">Loading....</li>
          ) : (
            <></>
          )}
          {search && loading === "loaded" && !results.length ? (
            <li className="load-more">No more data</li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}
