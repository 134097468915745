/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CachedIcon from "@material-ui/icons/Cached";
import exportFromJSON from "export-from-json";
import { useAlert } from "react-alert";

const fillMissingData = (from, hour, data) => {
  const newdata = [];
  for (let i = 0; i < hour + 1; i++) {
    const hour = new Date(from + i * 60 * 60 * 1000).getHours();
    const dataIndex = data.findIndex((dt) => dt._id === hour);
    newdata.push(dataIndex > -1 ? data[dataIndex] : { _id: hour, users: 0 });
  }
  return newdata;
};

const Graph = ({ setBarGraphLoader }) => {
  const [graphVisitors, setGraphVisitors] = useState([]);

  const alert = useAlert();

  useEffect(() => {
    const fetchVisitorsGraph = async () => {
      const authToken = localStorage.getItem("authToken");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/last12hourpagevisitbystudents?hour=24`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          console.log(response.data);
          setGraphVisitors(
            fillMissingData(
              response.data.data.from,
              parseInt(response.data.data.hours),
              response.data.data.pageVisits
            )
          );
        }
      } catch (error) {
        console.log(error);
        alert.show(error.message, { type: "error" });
      }
    };

    fetchVisitorsGraph();
  }, []);

  const updateVisitorsGraph = async () => {
    setBarGraphLoader(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/last12hourpagevisitbystudents?hour=24`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setGraphVisitors(
          fillMissingData(
            response.data.data.from,
            parseInt(response.data.data.hours),
            response.data.data.pageVisits
          )
        );
        alert.success("Data Updated.");
      }
    } catch (error) {
      alert.error("Error in updating data.");
    }

    setBarGraphLoader(false);
  };

  const exportCsv = () => {
    const data = graphVisitors;
    const fileName = 'GraphAnalytics';
    const modifiedData = data.map((d) => ({
      TIME: d._id >= 12 ? d._id + ' P.M' : d._id + ' A.M',
      USERS: d.users,
    }));
    console.log(modifiedData, 'from export');
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: modifiedData, fileName, exportType });
  };

  return (
    <>
      <div className="row mb-3 text-center">
        <div className="col-sm-6 text-sm-start fw-bolder">
          <h4>
            Page Visits{" "}
            <span style={{ cursor: "pointer" }} onClick={updateVisitorsGraph}>
              <CachedIcon />
            </span>{" "}
          </h4>
          <p>Page visits in last 24 hours</p>
        </div>
        <div className="col-sm-6 text-sm-end">
          <span
            className="fw-bold mx-3"
            style={{ cursor: "pointer" }}
            onClick={exportCsv}
          >
            <ExitToAppIcon /> Export
          </span>
        </div>
      </div>
      <ResponsiveContainer aspect={2.5}>
        <BarChart
          width={500}
          height={300}
          data={graphVisitors}
          margin={{
            top: 5,
            right: 30,
            left: -20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey='_id'>
            <Label
              value='Nth hour of the day'
              offset={-2}
              position='insideBottom'
              fill='#5CE0D2'
            />
          </XAxis>
          <YAxis dataKey='users'>
            <Label
              value='Users'
              offset={0}
              position='outsideLeft'
              angle={-90}
              fill='#5CE0D2'
            />
          </YAxis>
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="users" offset={10} fill="#5CE0D2" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default Graph;
