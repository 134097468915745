import React, { createContext, useEffect, useState, useMemo } from "react";
import axios from "axios";

import GradeSorter from "../utils/gradeSorter";
import { sub } from "date-fns";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const [userDetails, setUserDetails] = useState({
    isAuthenticated: false,
    name: "",
    userId: "",
    schoolId: "",
    role: "",
    profileImageUrl: undefined,
    selectedTab: "Dashboard",
    pathName: {
      subjectName: "",
      chapterName: "",
      topicName: "",
    },
    questions: [],
    grades: [],
    filterquestions: [],
  });
  // const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [loading, setLoading] = useState(false);
  const [lang, setLanguage] = useState("english");
  const [eligible, setEligibility] = useState(false);
  const [assignedGrades, setAssignedGrades] = useState({
    loading: true,
    grades: [],
  });
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sectionIdTask, setSectionIdTask] = useState("");
  const [sectionId, setSectionId] = useState("");

  const subjectIds = useMemo(() => {
    if (assignedGrades.loading) return [];
    return assignedGrades.grades
      .map((grade) =>
        grade.subjects.map((sub) => {
          return {
            sectionId: sub.sectionId,
            gradeSection: grade._id,
            mainSubjectId: sub.subjectData?._id,
          };
        })
      )
      .flat(1)
      .reduce((prevValue, currentValue) => {
        prevValue[currentValue.mainSubjectId] = currentValue;
        return prevValue
      }, {});
  }, [assignedGrades]);

  useEffect(() => {
    const checkAuth = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/auth/validate/${authToken}`
        );
        if (response.data.status === "authorized") {
          setUserDetails({
            isAuthenticated: true,
            name: response.data.data.user.name,
            userId: response.data.data.user.userId,
            schoolId: response.data.data.user.school,
            role: response.data.data.user.role,
            grades: response.data.data.user.grades,
            sections: response.data.data.user.sections,
            selectedTab: "Dashboard",
            pathName: userDetails.pathName,
            profileImageUrl: window.localStorage.getItem("profileImageUrl"),
            questions: userDetails.questions,
            filterquestions: userDetails.filterquestions,
          });
        }

        setLoading(true);
      } catch (error) {
        setUserDetails({ isAuthenticated: false });
        setLoading(true);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (!userDetails.isAuthenticated || assignedGrades.grades.length) return;
    const fetchClasses = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/assignSubjects`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          setAssignedGrades({
            loading: false,
            grades: new GradeSorter(response.data.data.sections).sort(),
          });
          //set elgibility
          if (response.data.data.schoolId._id === "611ce503ded7800500d3b287") {
            setEligibility(true);
          }
          if (response.data.data?.schoolId?.board.startsWith("Chhattisgarh")) {
            setLanguage("hindi");
          }
        }
      } catch (error) {
        setAssignedGrades({
          loading: false,
          grades: [],
        });
        console.log(error);
      }
    };
    fetchClasses();
  }, [userDetails]);

  return (
    <>
      {loading && (
        <GlobalContext.Provider
          value={{
            userDetails,
            setUserDetails,
            lang,
            setLanguage,
            eligible,
            subjectIds,
            assignedGrades,
            setAssignedGrades,
            assignedSubjects,
            setAssignedSubjects,
            selectedClass,
            setSelectedClass,
            sectionIdTask,
            setSectionIdTask,
            sectionId,
            setSectionId,
          }}
        >
          {props.children}
        </GlobalContext.Provider>
      )}
    </>
  );
};
